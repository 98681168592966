<template>
  <ValidationObserver
    ref="refFormObserver"
    #default="{ invalid }"
  >
    <BModal
      id="modal-verify-bank-accounts"
      :title="$t('bankAccount.verifyBankAccount')"
      title-class="text-airline font-medium-4 font-weight-bolder"
      centered
      body-class="px-75"
      size="md"
      no-close-on-backdrop
      @show="showHandle"
    >
      <template #modal-footer="{ cancel }">
        <BButton
          variant="outline-secondary"
          class="center rounded-pill"
          @click="cancel()"
        >
          {{ $t('bankAccount.cancel') }}
        </BButton>

        <BButton
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="btn-gradient border-right-0"
          pill
          :disabled="invalid"
          @click="handleSubmit"
        >
          <div class="d-flex-center px-25">
            {{ $t('bankAccount.submit') }}
          </div>
        </BButton>
      </template>

      <!-- ANCHOR otp -->
      <ValidationProvider
        #default="validationContext"
        :name="$t('bankAccount.otp')"
        rules="required"
      >
        <BFormGroup label-for="otp">
          <template #label>
            <div class="text-nowrap">
              {{ $t('bankAccount.otp') }}
              <span class="text-danger">(*)</span>
            </div>
          </template>
          <BFormInput
            id="otp"
            v-model="dataToVerify.otp"
            :state="getValidationState(validationContext) === false ? false : null"
            lazy-formatter
            :formatter="removeAccentsUpperCaseFormatter"
            :placeholder="$t('bankAccount.ph.otp')"
          />

          <BFormInvalidFeedback>
            {{ validationContext.errors[0] }}
          </BFormInvalidFeedback>
          <!-- ANCHOR btnResend. -->
          <b-link
            id="btnResend"
            v-b-tooltip.hover.v-dark.window="$t('bankAccount.resend')"
            @click="handleResend"
          >
            {{ $t('bankAccount.resend') }}
          </b-link>
        </BFormGroup>
      </ValidationProvider>
    </BModal>
  </ValidationObserver>
</template>
<script>
import { ref } from '@vue/composition-api'
import {
  BButton,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BLink,
  BModal,
} from 'bootstrap-vue'
import cloneDeep from 'lodash/cloneDeep'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import { apiBankAccount } from '@/api'

import formValidation from '@core/comp-functions/forms/form-validation'
import { removeAccentsUpperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

import useToast from '@useToast'

export default {
  components: {
    BModal,
    BButton,
    BFormInvalidFeedback,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BLink,
  },
  props: {
    bankAccount: {
      type: Object,
      default: () => { },
    },
  },
  setup(props, { emit }) {
    const { toastSuccess, toastError } = useToast()

    const dataToVerify = ref({ otp: null })

    const resetDataToVerify = () => {
      dataToVerify.value = cloneDeep({ otp: null })
    }
    const { refFormObserver, getValidationState } = formValidation(resetDataToVerify)

    function handleSubmit() {
      this.$bvModal.show('modal-api-loading')
      apiBankAccount.verifyBankAccount(props.bankAccount.id, dataToVerify.value)
        .then(() => {
          emit('fetch-bank-account')
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.settingAccount.verifyBankAccountSuccess',
          })
          this.$bvModal.hide('modal-verify-bank-accounts')
        })
        .catch(() => {
          toastError({
            title: 'messagesList.error',
            content: 'messagesList.settingAccount.verifyBankAccountError',
          })
        }).finally(() => {
          this.$bvModal.hide('modal-api-loading')
        })
    }

    // resend otp

    function handleResend() {
      this.$bvModal.show('modal-api-loading')
      apiBankAccount.resendOTP(props.bankAccount.id)
        .then(() => {
          toastSuccess({
            title: 'messagesList.success',
            content: 'messagesList.settingAccount.resendBankAccountSuccess',
          })
        })
        .catch(() => {
          toastError({
            title: 'messagesList.error',
            content: 'messagesList.settingAccount.resendBankAccountError',
          })
        }).finally(() => {
          this.$bvModal.hide('modal-api-loading')
        })
    }

    function showHandle() {
      resetDataToVerify()
    }
    return {
      dataToVerify,
      handleSubmit,
      handleResend,
      refFormObserver,
      getValidationState,
      removeAccentsUpperCaseFormatter,
      showHandle,
    }
  },
}
</script>

<style lang="scss" scoped>
#is-default-div ::v-deep {
  .custom-control-label {
    width: 100%;
    color: black;
  }
}
</style>
